.react-chatbot-kit-chat-bot-message-container {
    display: flex;
    justify-content: flex-start;
    padding: 10px;
}

.react-chatbot-kit-user-chat-message-container {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    border-radius: 5px;
    font-size: 0.9rem;
    color: #585858;
    font-weight: medium;
    position: relative;
    text-align: left;
}

.react-chatbot-kit-user-chat-message {
    padding: 10px;
    border-radius: 5px;
    font-size: 0.9rem;
    color: #585858;
    font-weight: medium;
    position: relative;
    text-align: left;
}

.react-chatbot-kit-chat-bot-message {
    margin-left: 12px;
    width: fit-content;
    display: flex;
    margin: 15px 0;
    justify-content: flex-start;
    font-size: smaller;
    margin: auto;
    padding: 10px;
    border-radius: 5px;
    color: #fff;
    font-weight: medium;
    position: relative;
    text-align: left;
}

.react-chatbot-kit-chat-bot-message-arrow {
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    position: absolute;
    left: -7px;
    top: 13px;
}

.react-chatbot-kit-chat-message-container {
    padding: 0 17.5px 10px 17.5px;
    overflow: scroll;
    overflow-y: overlay;
    overflow-x: hidden;
    height: 424px;
}