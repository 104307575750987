.dragging {
    background-color: '#eee';
    cursor: 'copy';
    border: '2px dashed #ccc';
    border-radius: '4px';
    padding: '20px';
    text-align: 'center';
    height: '150px'
}

/* CSS */
@keyframes chomp {
    from {
        stroke-dasharray: 157, 100;
        stroke-dashoffset: 0;
    }

    to {
        stroke-dasharray: 126, 100;
        stroke-dashoffset: -15;
    }
}

@keyframes dots {
    from {
        width: 95%;
    }

    to {
        width: 5%;
    }
}

.pacman-environment {
    box-sizing: border-box;
    background-color: transparent;
    width: 100%;
    overflow: hidden;
}

.border {
    height: 12px;
    width: 100%;
    border-radius: 12px;
}

.path {
    position: relative;
    margin: 10px -200px;
    height: 20px;
    width: 120%;
    transform: translateX(20%);
}

.pacman {
    height: 40px;
    transform: translate(-2px, -9px);
}

circle {
    stroke: #f7d52f;
    stroke-width: 50%;
    fill: none;
    animation: chomp .15s linear infinite alternate;
}

.dots {
    float: right;
    width: 80%;
    height: 100%;
    transform: translateY(5px);
    background-image: url(./ms_word_icon.svg);
    background-repeat: repeat-x;
    background-position: center right;
    background-size: 18px 12px;
    background-repeat: repeat-x;
    animation: dots 5s linear infinite;
}